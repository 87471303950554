/* requires:
polyfill.js
*/

// Dropdown Select Toggle
(function () {
  const activeClass = 'is-active';
  const hiddenClass = 'invisible';
  const dropdownCollection = document.querySelectorAll('.dropdown-list span.dropdown');

  const setAria = (elem, value) => {
    elem.setAttribute('aria-expanded', value);
    elem.setAttribute('aria-pressed', value);
  };

  const toggleDropdown = (dropdownToggle, action) => {
    const dropdown = dropdownToggle.nextElementSibling;

    if (dropdownToggle.classList.contains(activeClass) || action === 'close') {
      setAria(dropdownToggle, false);
      dropdown.classList.remove(activeClass);
      dropdownToggle.classList.remove(activeClass);
    } else {
      // closing all other dropdowns before opening a different one
      for (let toggle of dropdownCollection) {
        setAria(toggle, false);
        toggle.classList.remove(activeClass);
        toggle.nextElementSibling.classList.remove(activeClass);
      }
      setAria(dropdownToggle, true);
      dropdown.classList.remove(hiddenClass); // needs to be before removing adding the is-active class
      dropdownToggle.classList.add(activeClass);
      dropdown.classList.add(activeClass);
    }
  };

  for (let dropdownToggle of dropdownCollection) {
    const dropdown = dropdownToggle.nextElementSibling;

    setAria(dropdownToggle, false);
    dropdown.classList.add(hiddenClass);
    dropdownToggle.addEventListener('click', () => toggleDropdown(dropdownToggle));

    dropdownToggle.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggleDropdown(dropdownToggle);
      }
    });

    // needs to be here so that we only add one event listener to each element
    dropdown.addEventListener('transitionend', () => {
      if (dropdown.offsetHeight === 0) {
        dropdown.classList.add(hiddenClass);
      } else {
        dropdown.classList.remove(hiddenClass);
      }
    });
  }

  // closing the dropdown if the tabbed element is no longer the dropdown
  let prevTabbedDropdown = null;
  document.addEventListener('keyup', (e) => {
    const targetEl = e.target;
    const currTabbedDropdown = targetEl.closest('.dropdown-list');

    // tabbing on mobile for main nav
    if (e.key === 'Tab') {
      if (!currTabbedDropdown || prevTabbedDropdown !== currTabbedDropdown) {
        const prevDropdownTrigger = prevTabbedDropdown?.querySelector('span.dropdown');

        if (prevDropdownTrigger) {
          toggleDropdown(prevDropdownTrigger, 'close');
        }
      }

      prevTabbedDropdown = currTabbedDropdown;
    }

    // close entire menu on mobile and close dropdown on desktop
    if (e.key === 'Escape') {
      if (currTabbedDropdown) {
        const dropdownToggle = currTabbedDropdown.querySelector('span.dropdown');
        toggleDropdown(dropdownToggle, 'close');
      }
    }
  });

  document.addEventListener(
    'click',
    function (e) {
      // Dropdown Select Toggle
      const eventElement = e.target;
      if (!eventElement.closest('.dropdown-list')) {
        //console.log(event_element.closest(".dropdown-list"));
        for (let dropdownToggle of dropdownCollection) {
          toggleDropdown(dropdownToggle, 'close');
        }
      }
    },
    false
  );
})();
