/* requires:
polyfill.js
*/

// accordion Toggle
(function () {
  const activeClass = 'is-open';
  const hiddenClass = 'invisible';
  const accordionToggleCollection = document.querySelectorAll('.accordion .accordion-toggle');

  const toggleAccordion = (e) => {
    const target = e.target.closest('.accordion-toggle');

    // toggle aria-expanded and aria-pressed attributes
    target.setAttribute('aria-expanded', target.getAttribute('aria-expanded') === 'false' ? 'true' : 'false');
    target.setAttribute('aria-pressed', target.getAttribute('aria-pressed') === 'false' ? 'true' : 'false');

    const accordion = target.closest('.accordion');
    const accordionContent = target.nextElementSibling;

    // support for nested accordion
    const nestedAccordionContents = accordionContent.querySelectorAll('.accordion-content');
    let nestedAccordionContentsTotalHeight = 0;
    for (let content of nestedAccordionContents) {
      nestedAccordionContentsTotalHeight = nestedAccordionContentsTotalHeight + content.scrollHeight;
    }

    if (accordion.classList.contains(activeClass)) {
      accordion.classList.remove(activeClass);
      accordionContent.classList.remove(activeClass);
      accordionContent.style.maxHeight = null;
    } else {
      accordionContent.classList.remove(hiddenClass);
      accordion.classList.add(activeClass);
      accordionContent.classList.add(activeClass);
      accordionContent.style.maxHeight = accordionContent.scrollHeight + nestedAccordionContentsTotalHeight + 50 + 'px';
    }
  };

  for (let accordionToggle of accordionToggleCollection) {
    const accordionContent = accordionToggle.nextElementSibling;

    accordionToggle.setAttribute('aria-expanded', 'false');
    accordionToggle.setAttribute('aria-pressed', 'false');

    accordionToggle.addEventListener('click', toggleAccordion);
    accordionToggle.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggleAccordion(e);
      }
    });

    accordionContent.classList.add(hiddenClass);

    // needs to be here so that we only add one event listener to each element
    accordionContent.addEventListener('transitionend', () => {
      if (accordionContent.offsetHeight === 0) {
        accordionContent.classList.add(hiddenClass);
      } else {
        accordionContent.classList.remove(hiddenClass);
      }
    });
  }
})();
